@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  textarea,
  select,
  select[multiple] {
    @apply border-gray-300 rounded-md focus:border-rose-500 focus:ring-rose-500 focus:ring-2 w-full;
  }

  [type="checkbox"],
  [type="radio"] {
    @apply text-rose-500 focus:border-rose-500 focus:ring-rose-500 h-5 w-5;
  }
  [type="checkbox"] {
    @apply rounded;
  }
}
